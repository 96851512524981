<template>
	<div class="row">
		<div v-if="FEATURE.userName" class="form-group col-12">
			<label for="name">Krstné meno *</label>
			<input v-model="userInfo.name" class="form-control" placeholder="Zadajte meno" type="text" autocomplete="none">
		</div>

		<div v-if="FEATURE.userSurname" class="form-group col-12">
			<label for="surname">Priezvisko *</label>
			<input v-model="userInfo.surname" class="form-control" placeholder="Zadajte priezvisko" type="text" autocomplete="none">
		</div>

		<div v-if="FEATURE.userUsername" class="form-group col-12">
			<label for="username">Používateľské meno *</label>
			<input v-model="userInfo.username" class="form-control" placeholder="Zadajte používateľské meno" type="text" autocomplete="none">
		</div>

		<div v-if="FEATURE.userEmail" class="form-group col-12">
			<label for="emailAddress">Emailová adresa *</label>
			<input v-model="userInfo.email" class="form-control" placeholder="Zadajte email" type="email" autocomplete="none">
		</div>

		<div v-if="FEATURE.userSchool" class="form-group col-12">
			<label for="school">Vyberte na akú školu chodíte *</label>
			<select v-model="userInfo.school" class="form-control">
				<option v-for="(option, i) in schoolOptions" :key="i" :value="option.value">
					{{ option.text }}
				</option>
			</select>
		</div>
	</div>
</template>
z
<script>
export default {
	props: {
		userInfo: { required: true }
	},

	data() {
		return {
			schoolOptions: [
				{ value: 'Základná škola', text: 'Základná škola' },
				{ value: 'Stredná škola', text: 'Stredná škola' },
				{ value: 'Vysoká škola', text: 'Vysoká škola' },
				{ value: 'Niesom študent', text: 'Nechodím do školy' }
			]
		}
	}
}
</script>